import React, { useEffect, useState } from "react";
import "./index.scss";
import Sidebar from "../../components/sideBar";
import Card from "../../components/card";
import Popup from "../../components/Popup";
import roleService from "../../Services/Roles";
import Masonry from "react-masonry-css";
import Lottie from "react-lottie";
import empty from "../../Assets/empty.json";
import Layer from "../Layer";

const Roles: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [allRoles, setAllRoles] = useState<any[]>([]); // Adjust the type accordingly
  const roless = [
    {
      Role: "manager",
      id: "647706c3fed0b27e8268710a",
      Apps: ["Store", "Oms", "Inventory", "Management", "Achat"],
    },
    {
      Role: "PizzaYolo",
      id: "6686d20b281b470e9c38791d",
      Apps: ["Store"],
    },
    {
      Role: "manager",
      id: "647706c3fed0b27e8268710a",
      Apps: ["Store", "Oms", "Inventory", "Management", "Achat"],
    },
    {
      Role: "PizzaYolo",
      id: "6686d20b281b470e9c38791d",
      Apps: ["Store"],
    },
    {
      Role: "manager",
      id: "647706c3fed0b27e8268710a",
      Apps: ["Store", "Oms", "Inventory", "Management", "Achat"],
    },
    {
      Role: "PizzaYolo",
      id: "6686d20b281b470e9c38791d",
      Apps: ["Store"],
    },
  ];

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  // Callback function to trigger the effect after edit
  const handleEditFinish = () => {
    const fetchRoles = async () => {
      try {
        const roles = await roleService.getAllRoles();
        setAllRoles(roles.content);
      } catch (error) {
        console.error("Error fetching roles:", error);
        // Handle error
      }
    };

    fetchRoles();
  };

  // Fetch all roles when the component mounts
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const roles = await roleService.getAllRoles();
        setAllRoles(roles.content);
      } catch (error) {
        console.error("Error fetching roles:", error);
        // Handle error
      }
    };

    fetchRoles();
  }, [isPopupOpen]); // Update dependency array

  return (
    <Layer title={"roles"}>
      <div className="roles-page">
        <div className="roles-header">
          <span data-testid="roles-title">Roles</span>
          <button
            className="purple-button"
            onClick={handleOpenPopup}
            data-testid="add-button"
          >
            Ajouter
          </button>
        </div>
        {allRoles.length > 0 ? (
          <Masonry
            breakpointCols={{ default: 4, 1280: 4, 700: 4, 500: 4 }}
            className="masonry-grid"
            columnClassName="masonry-column"
          >
            {allRoles.map((role) => (
              <Card key={role.id} role={role} onEditFinish={handleEditFinish} />
            ))}
          </Masonry>
        ) : (
          <div className="empty-list">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: empty,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={500}
              width={500}
            />
          </div>
        )}
        <Popup
          isOpen={isPopupOpen}
          onRequestClose={handleClosePopup}
          data-testid="popup"
        />
      </div>
    </Layer>
  );
};

export default Roles;
